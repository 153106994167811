import { Sash } from "./utils/Sash";
import Link, { useNavigate } from "react-router-dom";

import { ImageGrid } from "./utils/ImageGrid";
import { ImageGridCell } from "../interfaces/ImageGridCell";
import { useState } from "react";



export default function Page() {
    const [bannerloaded, setBannerLoaded] = useState(false);

    const photoDir = "./public/merch/images";
    const jsonFile = "./public/merch/merch.json";
    const placeHolder = "Merchandise items coming soon!";

    const navigate = useNavigate();

    const handleContactClick = async () => {
        // Navigate to "events/upcoming" first
        await navigate(`/contact`);
        window.scroll(0, 0);

        // Wait for a short duration before navigating to the specific event section
        setTimeout(() => {
            const eventSection = document.getElementById("contact-form");
            if (eventSection) {
                eventSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    };

    return (
        <div
            style={{
                transition: "opacity 0.5s ease-in-out",
                opacity: bannerloaded ? 1 : 0,
            }}
        >
            <div className=" bg-colour-dark">
                <div className="relative">
                    <div className=" absolute inset-0 flex flex-col justify-center pointer-events-none pt-[25%] md:pt-[20%] pl-[2%] pr-[2%] md:pl-[5%] sm:px-[10%]  lg:pr-[3%]">
                        <div className="text-white text-left pb-[30%]">
                            <h1 className="text-3xl sm:text-4xl midmd:text-5xl lg:text-7xl xl:text-8xl 2xl:text-9xl  font-bold">
                                Merchandise
                            </h1>
                        </div>
                    </div>
                    <img
                        src="/assets/banners/merch/merch.jpg"
                        width={1920}
                        height={1080}
                        alt="Dancers Cover"
                        loading="eager"
                        className="object-cover midmd:w-full h-auto md:h-full"
                        onLoad={() => setBannerLoaded(true)}
                    />
                </div>
            </div>

            <div className="bg-colour-light pt-[2%] pb-[4%] md:pb-[0%]">
                    <div className=" margin-centered pb-[2%]">
                        <p className="font-bold text-black text-md lg:text-lg xl:text-3xl pb-5">
                            Interested in repping some merch from us? Send us a message and we'll get in touch with you!
                        </p>
                        <span
                            className={`px-4 py-3 font-semibold shadow-md no-underline rounded-full bg-yevshan-gold hover:cursor-pointer text-black md:text-lg lg:text-xl border-blue btn-primary  hover:bg-[#b8b795] focus:outline-none active:shadow-none mr-2 whitespace-nowrap`}
                            onClick={() => handleContactClick()}
                        >
                            {`Contact us`}
                        </span>
                    </div>
                </div>

            <div className="bg-colour-dark py-[5%] px-[5%]">
                <ImageGrid
                    photoDir={photoDir}
                    jsonFile={jsonFile}
                    placeHolder={placeHolder}
                />
            </div>

            <Sash />
        </div>
    );
}
