import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Navbar } from "./components/utils/Navbar";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./components/Home";
import History from "./components/History";
import { Footer } from "./components/utils/Footer";
import Privacy from "./components/Privacy";
import ArtisticDirector from "./components/ArtisticDirector";
import Dancers from "./components/Dancers";
import BoardOfDirectors from "./components/BoardOfDirectors";
import Upcoming from "./components/Upcoming";
import Past from "./components/Past";
import Joinus from "./components/Joinus";
import Contact from "./components/Contact";
import MailingList from "./components/MailingList";
import Merch from "./components/Merch";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/history" element={<History />}></Route>
                    <Route
                        path="/artistic-director"
                        element={<ArtisticDirector />}
                    ></Route>
                    <Route path="/dancers" element={<Dancers />}></Route>
                    <Route
                        path="/board-of-directors"
                        element={<BoardOfDirectors />}
                    ></Route>
                    <Route path="/merch" element={<Merch />}></Route>
                    <Route path="/upcoming" element={<Upcoming />}></Route>
                    <Route path="/past" element={<Past />}></Route>
                    <Route path="/joinus" element={<Joinus />}></Route>
                    <Route path="/contact" element={<Contact />}></Route>
                    <Route path="/privacy" element={<Privacy />}></Route>
                    <Route path="/mailing" element={<MailingList />}></Route>
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
